<template>
  <div
    class="fixed top-0 left-0 h-screen w-screen bg-white bg-opacity-90 flex items-center justify-center z-50"
  >
    <div class="flex flex-col items-center space-y-8">
      <Logo class="w-48" invert />
      <UIcon name="i-mdi-loading" class="size-12 animate-spin"></UIcon>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
